import React, { useState } from "react";
import Input from "../facultydashboard/formGroup/Input";

import { useNavigate } from "react-router-dom";
import axios from "axios";
import { message } from "antd";
import Spinner from "../facultydashboard/formGroup/Spinner";

function PlacementForm() {
  const [studentName, setStudentName] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [program, setProgram] = useState("");
  const [session, setSession] = useState("");
  const [passOutYear, setPassOutYear] = useState("");
  const [registrationNo, setRegistrationNo] = useState("");
  const [currentlyPlaced, setCurrentlyPlaced] = useState("");
  const [designation, setDesignation] = useState("");
  const [salaryPerYear, setSalaryPerYear] = useState("");
  const [universityRollNo, setUniversityRollNo] = useState("");

  const [loader, setLoader] = useState(false);

  const navigate = useNavigate();

  const feedbackStudent = () => {
    setLoader(true);
    axios
      .post("/api/v1/placement", {
        studentName,
        mobileNo,
        program,
        session,
        passOutYear,
        registrationNo,
        currentlyPlaced,
        designation,
        salaryPerYear,
        universityRollNo,
      })
      .then(function (response) {
        // console.log(response);
        setLoader(false);
        message.success(response.data.message);
        navigate("/thank-you");
      })
      .catch(function (error) {
        console.log(error);
        setLoader(false);
        message.error(error.response.data.errors);
      });
  };
  const onSubmit = (e) => {
    // setState({ [i.target.name]: "" })
    e.preventDefault();

    feedbackStudent();
  };
  return (
    <>
      {loader && <Spinner />}
      <div>
        <div className="grid z-50 grid-cols-1 mt-8 gap-y-2 justify-items-center my-auto">
          <div className="bg-white  font-bold  rounded-lg w-5/6 mx-4 shadow  px-4 py-2 ">
            <h1 className="text-4xl  text-center mb-2">
              B. N. Mandal University Madhepura बी. एन. मंडल विश्वविद्यालय,
              मधेपुरा
            </h1>
            <h1 className="text-3xl  text-center mb-2">
              Student Placement Information, English, BNMU
            </h1>
          </div>
          <div></div>
          <div className="bg-white tst rounded-lg w-5/6 mx-4 shadow-xl  px-4 py-2 ">
            <form
              onSubmit={onSubmit}
              className="w-5/6 grid mx-auto my-auto items-center md:grid-cols-2 gap-x-4 gap-y-2 "
            >
              <Input
                label="Student Name"
                name="studentName"
                man="*"
                type="text"
                value={studentName}
                onChange={(e) => {
                  setStudentName(e.target.value);
                }}
                placeholder=""
              />
              <Input
                label="Mobile No."
                man="*"
                name="mobileNo"
                type="number"
                value={mobileNo}
                onChange={(e) => {
                  setMobileNo(e.target.value);
                }}
                placeholder=""
              />
              <div>
                <label for="gender">
                  program
                  <span className="text-red-500 font-bold text-[1.2rem]">
                    *
                  </span>
                </label>
                <select
                  value={program}
                  onChange={(e) => {
                    setProgram(e.target.value);
                  }}
                  id="category"
                  class={`w-full h-9  rounded-sm border focus:border-gray-700  text-sm focus:outline-none focus:bg-white bg-gray-200 `}
                >
                  <option className="" value="">
                    -----Select-----
                  </option>
                  <option className="" value="Msc">
                    Msc
                  </option>
                  {/* <option className="" value='1'>Admin</option> */}
                  <option className="" value="PhD">
                    PhD
                  </option>
                </select>
              </div>

              <Input
                label="Session/Admission Year (For MSc Session, for PhD Admission Year)
                "
                name="session"
                man="*"
                type="text"
                value={session}
                onChange={(e) => {
                  setSession(e.target.value);
                }}
                placeholder=""
              />
              <Input
                label="Pass out Year"
                name="passOutYear"
                man="*"
                type="text"
                value={passOutYear}
                onChange={(e) => {
                  setPassOutYear(e.target.value);
                }}
                placeholder=""
              />

              <Input
                label="Registration No"
                name="registrationNo"
                man="*"
                type="text"
                value={registrationNo}
                onChange={(e) => {
                  setRegistrationNo(e.target.value);
                }}
                placeholder=""
              />
              <Input
                label="University Roll No"
                name="universityRollNo"
                man=""
                placeholder="2018"
                type="text"
                value={universityRollNo}
                onChange={(e) => {
                  setUniversityRollNo(e.target.value);
                }}
              />
              <div>
                <label for="category">
                  Currently Placed (Those are doing PhD, please choose Higher
                  Education)
                  <span className="text-red-500 font-bold text-[1.2rem]">
                    *
                  </span>
                </label>
                <select
                  value={currentlyPlaced}
                  onChange={(e) => {
                    setCurrentlyPlaced(e.target.value);
                  }}
                  id="category"
                  class={`w-full h-9  rounded-sm border focus:border-gray-700  text-sm focus:outline-none focus:bg-white bg-gray-200 `}
                >
                  <option className="" value="">
                    -----Select-----
                  </option>
                  <option className="" value="Government Sector">
                    Government Sector
                  </option>
                  {/* <option className="" value='1'>Admin</option> */}
                  <option className="" value="Private Sector">
                    Private Sector
                  </option>
                  <option className="" value="Higher Education">
                    Higher Education
                  </option>
                </select>
              </div>

              <Input
                label='Designation (Please write your job post if you are doing job. Those who are doing PhD please write "Research Scholar")'
                name="presentDesignation"
                man="*"
                type="text"
                value={designation}
                onChange={(e) => {
                  setDesignation(e.target.value);
                }}
                placeholder=""
              />
              <Input
                label='Salary per year (Those who are doing PhD and not getting any fellowship, please write "0" )'
                name="presentDesignation"
                man="*"
                type="text"
                value={salaryPerYear}
                onChange={(e) => {
                  setSalaryPerYear(e.target.value);
                }}
                placeholder=""
              />

              <div className="col-span-full">
                <div className="flex justify-center mt-2">
                  <button className="mt-3 text-white bg-gradient-to-r from-teal-400 via-teal-500 to-teal-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-teal-300 dark:focus:ring-teal-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2">
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default PlacementForm;
